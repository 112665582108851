import { storeToRefs } from 'pinia'
import { IRetailer } from '~/types/retailer'
import { useFiltersStore } from '~/stores/filtersStore'

export const useLocalRetailers = () => {
  const filtersStore = useFiltersStore()
  // Enhanced Retailers
  const localRetailers = ref<IRetailer[]>([])

  const isNew = computed(() => filtersStore.isNew)

  const onAccepted = (position: GeolocationPosition) => {
    const { coords } = position
    const { latitude, longitude } = coords

    fetchLocalRetailers(latitude, longitude)
  }

  const onBlocked = () => {
    const { retailer } = storeToRefs(filtersStore)
    const { Latitude, Longitude } = retailer.value || {}

    if (!Latitude || !Longitude) return

    fetchLocalRetailers(Latitude, Longitude)
  }

  const fetchLocalRetailers = async (lat: number, lon: number) => {
    const retailers = await useFetchRetailerByLocation(lat, lon, isNew.value, 4)

    localRetailers.value = retailers || []
  }

  useGeolocator(onAccepted, onBlocked)

  return {
    localRetailers
  }
}
