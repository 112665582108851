<template>
  <div v-click-outside="close" class="retailerAutocomplete">
    <div class="row no-gutters">
      <div
        class="col-12 col-s"
        :class="{ 'retailer-autocomplete__input-col': !isNew }"
      >
        <div>
          <p class="text-base text-gray-900 mb-4">
            Use your postcode or location to find your nearest showroom...
          </p>
        </div>

        <InputLocation
          class="retailerAutocomplete__wrapper mb-4"
          label="Postcode or Showroom name"
          :model-value="query"
          :is-loading="isLoading"
          :results="filteredRetailers"
          :tracking-options="trackingOptions"
          :rules="rules"
          :hide-results="!isDropdownOpen"
          :pattern="'[a-zA-Z0-9\- ]+'"
          @update:model-value="handleInput"
          @located="onLocated"
          @selected="onSelectRetailer"
          @focus="onInputFocus"
        >
          <template #default="{ result }">
            <span>{{ result.Description }}</span>
            <span v-if="result.DriveTime" class="retailerAutocomplete__time">
              {{ asMinutes(result.DriveTime) }}
            </span>
          </template>
        </InputLocation>
      </div>
      <div class="col-12 col-sm-auto retailerAutocomplete__radius">
        <InputDistance
          v-if="!isNew"
          :model-value="100"
          disabled
          expand-upwards
        />
      </div>
    </div>

    <div v-if="includeLocal && localRetailers.length > 0">
      <p class="text-base text-gray-900 mb-4">
        ...or choose from your nearest showrooms
      </p>
      <ul class="flex flex-wrap">
        <BadgeItem
          v-for="retailer in localRetailers"
          :key="retailer.Id"
          class="mr-2 mb-2 last:mb-0 cursor-pointer"
          :is-active="isSelectedRetailer(retailer)"
          :dark="useDarkBadge"
          @click="toggleSelectRetailer(retailer)"
        >
          {{ retailer.Description }}
        </BadgeItem>
      </ul>
    </div>

    <OsButton
      v-if="showReset && !isNew"
      :has-shadow="false"
      class="inline-button mt-2"
      @click.prevent="onResetRetailer"
    >
      <OsIcon name="Reset" class="text-blue-600" />
      Reset Showroom
    </OsButton>
  </div>
</template>

<script setup lang="ts">
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import clickOutside from '~/directives/clickOutside'
import { IRetailer } from '~/types/retailer'
import { useFiltersStore } from '~/stores/filtersStore'
import ErrorMessages from '~/constants/errorMessages'
import { ECondition } from '~/types/vehicle'

defineOptions({
  name: 'RetailerAutocomplete',
  directives: { clickOutside }
})

const props = defineProps({
  showReset: {
    type: Boolean,
    required: false,
    default: false
  },
  trackingOptions: {
    type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
    default: () => {
      return {
        category: 'retailer_auto_complete'
      }
    },
    required: false
  },
  vehicleCondition: {
    type: Number as PropType<ECondition>,
    required: false,
    default: null
  },
  isTradeIn: {
    type: Boolean,
    required: false,
    default: false
  },
  initialValue: {
    type: String,
    required: false,
    default: ''
  },
  useDarkBadge: {
    type: Boolean,
    required: false,
    default: false
  },
  // Toggle the local retailer search
  includeLocal: {
    type: Boolean,
    required: false,
    default: true
  }
})

const retailerSelected = defineModel<IRetailer | null>({
  type: Object as PropType<IRetailer>,
  required: false,
  default: null
})

const updateRetailer = (retailer: IRetailer | null) => {
  retailerSelected.value = retailer
}

const { $dataLayer } = useNuxtApp()
const filtersStore = useFiltersStore()

const {
  isDropdownOpen,
  rules,
  query,
  retailers,
  handleInput,
  errorBag,
  close,
  isLoading,
  selectRetailer,
  resetRetailer,
  onLocated
} = useRetailerSearch(onRetailerSearch, updateRetailer, {
  query: retailerSelected.value
    ? retailerSelected.value.Description
    : props.initialValue
})

const { asMinutes } = useUi()

const isNew = computed(() => {
  if (!props.vehicleCondition) {
    return filtersStore.isNew
  }

  return props.vehicleCondition === ECondition.New
})

const filteredRetailers = computed<IRetailer[]>(() => {
  if (props.isTradeIn) {
    return filterRetailersBy(retailers.value, 'TradeInEnabled').slice(0, 3)
  }

  const retailerType = isNew.value ? 'IsNewCarRetailer' : 'IsUsedCarRetailer'

  return retailers.value
    .filter((retailer: IRetailer) =>
      isRetailerAvailable(retailer, retailerType)
    )
    .slice(0, 3)
})

const toggleSelectRetailer = (retailer: IRetailer) => {
  $dataLayer.linkClick({
    pageCategory: 'filter',
    category: 'filters',
    action: `nearest retailer:${retailer.Description}`,
    retailer
  })

  isSelectedRetailer(retailer) ? resetRetailer() : selectRetailer(retailer)
}

const isSelectedRetailer = (retailer: IRetailer): boolean => {
  return retailerSelected.value?.Description === retailer.Description
}

const onSelectRetailer = (retailer: IRetailer, index: number) => {
  $dataLayer.linkClick({
    action: `showroom ${Number(index + 1)}`,
    ...props.trackingOptions
  })

  selectRetailer(retailer)
}

const onInputFocus = () => {
  if (filteredRetailers.value.length) {
    isDropdownOpen.value = true
  }
}

const onResetRetailer = () => {
  resetRetailer()
}

function onRetailerSearch() {
  const isSelected = query.value === retailerSelected.value?.Description

  if (filteredRetailers.value.length === 0 && !isSelected) {
    errorBag.value.add(ErrorMessages.showroomSearch.empty)
    $dataLayer.linkClick({
      action: `entry error`,
      ...props.trackingOptions
    })
  }
}

onMounted(() => {
  if (!retailerSelected.value && isNew.value) {
    handleInput(query.value)
  }
})

const { localRetailers } = useLocalRetailers()
</script>

<style scoped lang="scss">
.retailer-autocomplete__input-col {
  @include viewport('sm', 'max-width') {
    margin-bottom: rem(16);
  }
  @include viewport('sm') {
    padding-right: rem(16);
  }
}
.retailerAutocomplete__radius {
  visibility: hidden;
  height: 0;
}
</style>
